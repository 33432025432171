<template>
  <div v-if="client !== null">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ client.name }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">Dettaglio</h2>
          </div>
          <div class="p-5">
            <table class="table show-table">
              <tbody>
              <tr>
                <th class="label">Nome</th>
                <td class="value">{{ client.name }}</td>
              </tr>
              <tr>
                <th class="label">Indirizzo</th>
                <td class="value">{{ client.address }}</td>
              </tr>
              <tr>
                <th class="label">Provincia</th>
                <td class="value">{{ client.province }}</td>
              </tr>
              <tr>
                <th class="label">CAP</th>
                <td class="value">{{ client.cap }}</td>
              </tr>
              <tr>
                <th class="label">Email</th>
                <td class="value">{{ client.email }}</td>
              </tr>
              <tr>
                <th class="label">Data</th>
                <td class="value">{{ client.data }}</td>
              </tr>
              <tr>
                <th class="label">Stato</th>
                <td class="value">
                  <div :class="{ 'text-theme-9': client.active, 'text-theme-6': ! client.active }">
                    <CheckSquareIcon class="w-4 h-4 mr-2" />
                    {{ client.active ? 'Active' : 'Inactive' }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-right mt-5">
              <router-link
                :to="{ name: 'clients.index' }"
                tag="a"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Indietro
              </router-link>
              <router-link
                :to="{ name: 'clients.edit', params: { client: client.id } }"
                tag="a"
                class="btn btn-primary w-24 mr-1"
              >
                Modificare
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">Licenze</h2>
          </div>
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible p-5">
            <table class="table table-auto">
              <thead>
              <tr>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Serial</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Nota</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(license, licenseKey) in client.licences"
                :key="licenseKey"
                class="intro-x"
              >
                <td class="border">{{ license.serial }}</td>
                <td class="border">{{ license.note }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-right mt-5">
              <router-link
                :to="{ name: 'clients.licenses.create', params: { client: client.id } }"
                tag="a"
                class="btn btn-primary w-24 mr-1"
              >
                Nuovo
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../store/axios'

export default {
  data() {
    return {
      client: null
    }
  },
  mounted() {
    this.fetchClient()
  },
  methods: {
    fetchClient() {
      axios
        .get(`/clients/${this.$route.params.client}`)
        .then((response) => {
          this.client = response.data
        })
    }
  }
}
</script>
