<template>
  <div class="top-bar">
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <div v-for="(breadcrumb, breadcrumbKey) in breadcrumbs" :key="`breadcrumb-${breadcrumbKey}`">
        <ChevronRightIcon v-if="breadcrumbKey > 0" class="breadcrumb__icon" />
        <router-link
          v-if="breadcrumb.route"
          :to="{ name: breadcrumb.route }"
          tag="a"
        >
          {{ breadcrumb.name }}
        </router-link>
        <a
          v-else
          href="javascript:void(0)"
          class="breadcrumb--active"
        >
          {{ breadcrumb.name }}
        </a>
      </div>
    </div>
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img alt="" src="https://www.gravatar.com/avatar?d=mp"/>
      </div>
      <div class="dropdown-menu w-56">
        <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">Administrator</div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              Administrator
            </div>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <a href="javascript:void(0);" @click="logout" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Esci
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumbs
    }
  },
  methods: {
    logout() {
      console.log('Logout')
    }
  }
}
</script>
