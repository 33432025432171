import axios from 'axios'
import Swal from 'sweetalert2'

axios.interceptors.request.use(function(config) {
  config.baseURL = 'https://audit.rstrt.it/api'

  return config
}, function(err) {
  return Promise.reject(err)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const { status } = error.response

  if (status === 401 || status === 419) {
    console.log('logout')
  }

  if (status === 403) {
    Swal.fire('Forbidden!', '', 'warning')
  }

  if (status === 404) {
    Swal.fire('Error', 'Not Found!', 'warning')
  }

  if (status === 500) {
    Swal.fire('Error!', '', 'danger')
  }

  return Promise.reject(error)
})
