<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Modificare Cliente</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box p-5">
          <div>
            <label for="client-edit-name" class="form-label">Nome</label>
            <input
              id="client-edit-name"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.name}"
              v-model="form.name"
              autofocus
            />
            <div v-if="errors.name" class="text-theme-6 mt-2">{{ errors.name[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-address" class="form-label">Indirizzo</label>
            <input
              id="client-edit-address"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.address}"
              v-model="form.address"
            />
            <div v-if="errors.address" class="text-theme-6 mt-2">{{ errors.address[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-province" class="form-label">Provincia</label>
            <input
              id="client-edit-province"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.province}"
              v-model="form.province"
            />
            <div v-if="errors.province" class="text-theme-6 mt-2">{{ errors.province[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-cap" class="form-label">CAP</label>
            <input
              id="client-edit-cap"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.cap}"
              v-model="form.cap"
            />
            <div v-if="errors.cap" class="text-theme-6 mt-2">{{ errors.cap[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-email" class="form-label">Email</label>
            <input
              id="client-edit-email"
              type="email"
              class="form-control w-full"
              :class="{'border-theme-6': errors.email}"
              v-model="form.email"
            />
            <div v-if="errors.email" class="text-theme-6 mt-2">{{ errors.email[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-data" class="form-label">Data</label>
            <input
              id="client-edit-data"
              type="date"
              class="form-control w-full"
              :class="{'border-theme-6': errors.data}"
              v-model="form.data"
            />
            <div v-if="errors.data" class="text-theme-6 mt-2">{{ errors.data[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="client-edit-active">Stato</label>
            <div class="mt-2">
              <input
                id="client-edit-active"
                type="checkbox"
                class="form-check-switch"
                :class="{'border-theme-6': errors.active}"
                v-model="form.active"
              />
              <div v-if="errors.active" class="text-theme-6 mt-2">{{ errors.active[0] }}</div>
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link
              :to="{ name: 'clients.index' }"
              tag="a"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Annulla
            </router-link>
            <button type="button" class="btn btn-primary w-24" @click="updateClient">Salva</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../store/axios'

export default {
  data() {
    return {
      form: {
        name: null,
        address: null,
        province: null,
        cap: null,
        email: null,
        data: null,
        active: false,
        licences: []
      },
      errors: []
    }
  },
  mounted() {
    this.fetchClient()
  },
  methods: {
    fetchClient() {
      axios
        .get(`/clients/${this.$route.params.client}`)
        .then((response) => {
          this.form = {
            name: response.data.name,
            address: response.data.address,
            province: response.data.province,
            cap: response.data.cap,
            email: response.data.email,
            data: response.data.data,
            active: response.data.active
          }
        })
    },
    updateClient() {
      axios
        .put(`/clients/${this.$route.params.client}/`, this.form)
        .then(() => {
          this.$router.push({ name: 'clients.index' })
        })
        .catch((e) => {
          this.errors = e.response.data
        })
    }
  }
}
</script>
