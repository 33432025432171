<template>
  <div v-if="printer !== null">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ printer.name }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">Dettaglio</h2>
          </div>
          <div class="p-5">
            <table class="table show-table">
              <tbody>
              <tr>
                <th class="label">Nome</th>
                <td class="value">{{ printer.name }}</td>
              </tr>
              <tr>
                <th class="label">Fabbricante</th>
                <td class="value">{{ printer.manufacturer }}</td>
              </tr>
              <tr>
                <th class="label">Licenza</th>
                <td class="value">{{ printer.licence }}</td>
              </tr>
              <tr>
                <th class="label">Seriale</th>
                <td class="value">{{ printer.serial }}</td>
              </tr>
              <tr>
                <th class="label">Indirizzo IP</th>
                <td class="value">{{ printer.ip_address }}</td>
              </tr>
              <tr>
                <th class="label">Nota</th>
                <td class="value">{{ printer.note }}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-right mt-5">
              <router-link
                :to="{ name: 'printers.index' }"
                tag="a"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Indietro
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-12" v-if="counters.count">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">Counters</h2>
          </div>
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible p-5">
            <table class="table table-auto">
              <thead>
              <tr>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Pages 1 Color</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Pages 2 Color</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Pages BN</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Pages colour</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Pages Total</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Data</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(counter, counterKey) in counters.results"
                :key="`counter-${counterKey}`"
                class="intro-x"
              >
                <td class="border">{{ counter.pages_1_color }}</td>
                <td class="border">{{ counter.pages_2_color }}</td>
                <td class="border">{{ counter.pages_bn }}</td>
                <td class="border">{{ counter.pages_colour }}</td>
                <td class="border">{{ counter.pages_total }}</td>
                <td class="border">{{ counter.when }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-12" v-if="materials.count">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">Materials</h2>
          </div>
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible p-5">
            <table class="table table-auto">
              <thead>
              <tr>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Nome</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Codice</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Valore</th>
                <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Data</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(material, materialKey) in materials.results"
                :key="`material-${materialKey}`"
                class="intro-x"
              >
                <td class="border">{{ material.name }}</td>
                <td class="border">{{ material.code }}</td>
                <td class="border">{{ material.value }}</td>
                <td class="border">{{ material.when }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../store/axios'

export default {
  data() {
    return {
      printer: null,
      materials: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      counters: {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  mounted() {
    this.fetchPrinter()
    this.fetchPrinterCounters()
    this.fetchPrinterMaterials()
  },
  methods: {
    fetchPrinter() {
      axios
        .get(`/printers/${this.$route.params.printer}`)
        .then((response) => {
          this.printer = response.data
        })
    },
    fetchPrinterCounters() {
      axios
        .get(`/printers/${this.$route.params.printer}/counters`)
        .then((response) => {
          this.counters.count = response.data.count
          this.counters.next = response.data.next
          this.counters.previous = response.data.previous
          this.counters.results = response.data.results
        })
    },
    fetchPrinterMaterials() {
      axios
        .get(`/printers/${this.$route.params.printer}/materials`)
        .then((response) => {
          this.materials.count = response.data.count
          this.materials.next = response.data.next
          this.materials.previous = response.data.previous
          this.materials.results = response.data.results
        })
    }
  }
}
</script>
