<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Macchine</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-8 mt-2">
        <div class="intro-y box p-5">
          <div class="flex flex-row">
            <div class="w-1/2 p-3 preview" v-if="clients.results.length">
              <label for="printers-search-client" class="form-label">Cliente</label>
              <Multiselect
                id="printers-search-client"
                placeholder="Cliente"
                v-model="client"
                :options="clients.results"
                :internal-search="true"
                :custom-label="clientLabel"
                :showLabels="false"
              >
              </Multiselect>
            </div>
            <div class="w-1/2 p-3 preview" v-if="licenses.results.length">
              <label for="licenses-search-client" class="form-label">Licenza</label>
              <Multiselect
                id="licenses-search-client"
                placeholder="Licenza"
                v-model="license"
                :options="licenses.results"
                :internal-search="true"
                :custom-label="licenseLabel"
                :showLabels="false"
              >
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible" v-if="printers.count">
        <table class="table table-report -mt-2">
          <thead>
          <tr>
            <th class="whitespace-nowrap">Nome</th>
            <th class="whitespace-nowrap">Fabbricante</th>
            <th class="whitespace-nowrap">Licenza</th>
            <th class="whitespace-nowrap">Seriale</th>
            <th class="whitespace-nowrap">Indirizzo IP</th>
            <th class="text-center whitespace-nowrap">Azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(printer, printerKey) in printers.results"
            :key="printerKey"
            class="intro-x"
          >
            <td>{{ printer.name }}</td>
            <td>{{ printer.manufacturer }}</td>
            <td>{{ printer.licence }}</td>
            <td>{{ printer.serial }}</td>
            <td>{{ printer.ip_address }}</td>
            <td class="table-report__action w-56">
              <div class="flex justify-center items-center">
                <router-link
                  :to="{ name: 'printers.view', params: { printer: printer.id } }"
                  tag="a"
                  class="flex items-center mr-3"
                >
                  <EyeIcon class="w-4 h-4 mr-1" /> Dettaglio
                </router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center" v-if="printers.count">
        <ul class="pagination">
          <li v-if="printers.previous" @click="fetchPrinters(printers.previous)">
            <a class="btn btn-primary shadow-md mr-2">
              <ChevronLeftIcon class="w-4 h-4" /> Previous
            </a>
          </li>
          <li v-if="printers.next" @click="fetchPrinters(printers.next)">
            <a class="btn btn-primary shadow-md mr-2">
              Next <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div>
          Macchine Totali: {{ printers.count }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../store/axios'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'

export default {
  components: { Multiselect },
  data() {
    return {
      client: null,
      license: null,
      clients: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      licenses: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      printers: {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  mounted() {
    this.fetchClients()
  },
  watch: {
    client() {
      this.license = null
      this.fetchLicenses()
    },
    license() {
      this.fetchPrinters()
    }
  },
  methods: {
    fetchClients() {
      axios
        .get('clients')
        .then((response) => {
          this.clients.count = response.data.count
          this.clients.next = response.data.next
          this.clients.previous = response.data.previous
          this.clients.results = response.data.results
        })
    },
    fetchLicenses() {
      if (this.client) {
        axios
          .get(`clients/${this.client.id}/licences`)
          .then((response) => {
            this.licenses.count = response.data.count
            this.licenses.next = response.data.next
            this.licenses.previous = response.data.previous
            this.licenses.results = response.data.results
          })
      } else {
        this.licenses.count = 0
        this.licenses.next = null
        this.licenses.previous = null
        this.licenses.results = []
        this.license = null
      }
    },
    fetchPrinters(url = null) {
      if (this.client && this.license) {
        if (url === null) {
          url = `clients/${this.client.id}/licences/${this.license.id}/printers`
        }

        axios
          .get(url)
          .then((response) => {
            this.printers.count = response.data.count
            this.printers.next = response.data.next
            this.printers.previous = response.data.previous
            this.printers.results = response.data.results

            if (response.data.results.length < 1) {
              Swal.fire('Nessuna macchine trovata!', '', 'warning')
            }
          })
      } else {
        this.printers.count = 0
        this.printers.next = null
        this.printers.previous = null
        this.printers.results = []
      }
    },
    clientLabel({ name }) {
      return name
    },
    licenseLabel({ serial }) {
      return serial
    }
  }
}
</script>
