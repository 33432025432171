<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Nuovo Licenza</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="intro-y box p-5">
          <div>
            <label for="license-create-serial" class="form-label">Serial</label>
            <input
              id="license-create-serial"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.serial}"
              v-model="form.serial"
              autofocus
            />
            <div v-if="errors.serial" class="text-theme-6 mt-2">{{ errors.serial[0] }}</div>
          </div>
          <div class="mt-3">
            <label for="license-create-note" class="form-label">Nota</label>
            <input
              id="license-create-note"
              type="text"
              class="form-control w-full"
              :class="{'border-theme-6': errors.note}"
              v-model="form.note"
            />
            <div v-if="errors.note" class="text-theme-6 mt-2">{{ errors.note[0] }}</div>
          </div>
          <div class="text-right mt-5">
            <router-link
              :to="{ name: 'clients.view', params: { client: this.$route.params.client } }"
              tag="a"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              Annulla
            </router-link>
            <button type="button" class="btn btn-primary w-24" @click="createClientLicense">Salva</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../../store/axios'

export default {
  data() {
    return {
      form: {
        serial: null,
        note: null,
        client: this.$route.params.client
      },
      errors: []
    }
  },
  mounted() {
    //
  },
  methods: {
    createClientLicense() {
      axios
        .post(`/clients/${this.$route.params.client}/licences/`, this.form)
        .then(() => {
          this.$router.push({ name: 'clients.view', params: { client: this.$route.params.client } })
        })
        .catch((e) => {
          this.errors = e.response.data
        })
    }
  }
}
</script>
