const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'dashboard',
        title: 'Dashboard'
      },
      'devider',
      {
        icon: 'UserIcon',
        pageName: 'clients.index',
        title: 'Clienti'
      },
      {
        icon: 'PrinterIcon',
        pageName: 'printers.index',
        title: 'Macchine'
      },
      'devider'
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
