<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Dashboard</h2>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-10" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ totalClients }}</div>
                  <div class="text-base text-gray-600 mt-1">Clienti Totali</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FileTextIcon class="report-box__icon text-theme-10" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ totalLicenses }}</div>
                  <div class="text-base text-gray-600 mt-1">Licenze Totali</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <PrinterIcon class="report-box__icon text-theme-10" />
                  </div>
                  <div class="text-3xl font-medium leading-8 mt-6">{{ totalPrinters }}</div>
                  <div class="text-base text-gray-600 mt-1">Macchine Totali</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalClients: 150,
      totalLicenses: 400,
      totalPrinters: 500
    }
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>
