<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Clienti</h2>
    <div class="grid grid-cols-12 gap-6 mt-5" v-if="clients.count">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <router-link
          :to="{ name: 'clients.create' }"
          tag="a"
          class="btn btn-primary shadow-md mr-2"
        >
          Nuovo
        </router-link>
      </div>
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
          <tr>
            <th class="whitespace-nowrap">Nome</th>
            <th class="whitespace-nowrap">Indirizzo</th>
            <th class="whitespace-nowrap">Provincia</th>
            <th class="whitespace-nowrap">CAP</th>
            <th class="whitespace-nowrap">Email</th>
            <th class="whitespace-nowrap">Data</th>
            <th class="whitespace-nowrap">Stato</th>
            <th class="text-center whitespace-nowrap">Azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(client, clientKey) in clients.results"
            :key="clientKey"
            class="intro-x"
          >
            <td>{{ client.name }}</td>
            <td>{{ client.address }}</td>
            <td>{{ client.province }}</td>
            <td>{{ client.cap }}</td>
            <td>{{ client.email }}</td>
            <td>{{ client.data }}</td>
            <td class="w-40">
              <div :class="{ 'text-theme-9': client.active, 'text-theme-6': ! client.active }">
                <CheckSquareIcon class="w-4 h-4 mr-2" />
                {{ client.active ? 'Active' : 'Inactive' }}
              </div>
            </td>
            <td class="table-report__action w-56">
              <div class="flex justify-center items-center">
                <router-link
                  :to="{ name: 'clients.view', params: { client: client.id } }"
                  tag="a"
                  class="flex items-center mr-3"
                >
                  <EyeIcon class="w-4 h-4 mr-1" /> Dettaglio
                </router-link>
                <router-link
                  :to="{ name: 'clients.edit', params: { client: client.id } }"
                  tag="a"
                  class="flex items-center mr-3"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-1" /> Modificare
                </router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
        <ul class="pagination">
          <li v-if="clients.previous">
            <a class="btn btn-primary shadow-md mr-2" @click="fetchClients(clients.previous)">
              <ChevronLeftIcon class="w-4 h-4" /> Previous
            </a>
          </li>
          <li v-if="clients.next">
            <a class="btn btn-primary shadow-md mr-2" @click="fetchClients(clients.next)">
              Next <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div>
          Clienti Totali: {{ clients.count }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../../store/axios'

export default {
  data() {
    return {
      clients: {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  mounted() {
    this.fetchClients()
  },
  methods: {
    fetchClients(url = 'clients') {
      axios
        .get(url)
        .then((response) => {
          this.clients.count = response.data.count
          this.clients.next = response.data.next
          this.clients.previous = response.data.previous
          this.clients.results = response.data.results
        })
    }
  }
}
</script>
