import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Dashboard from '../views/dashboard/Main.vue'
import ClientsIndex from '../views/clients/Index.vue'
import ClientsCreate from '../views/clients/Create.vue'
import ClientsView from '../views/clients/View.vue'
import ClientsEdit from '../views/clients/Edit.vue'
import ClientLicensesCreate from '../views/clients/Licenses/Create.vue'
import PrintersIndex from '../views/printers/Index.vue'
import PrintersView from '../views/printers/View.vue'
import Login from '../views/login/Main.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      breadcrumbs: []
    }
  },
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard' }
          ]
        }
      },
      {
        path: '/clients',
        name: 'clients.index',
        component: ClientsIndex,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Clienti' }
          ]
        }
      },
      {
        path: '/clients/create',
        name: 'clients.create',
        component: ClientsCreate,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Clienti', route: 'clients.index' },
            { name: 'Nuovo' }
          ]
        }
      },
      {
        path: '/clients/:client',
        name: 'clients.view',
        component: ClientsView,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Clienti', route: 'clients.index' },
            { name: 'Dettaglio' }
          ]
        }
      },
      {
        path: '/clients/:client/edit',
        name: 'clients.edit',
        component: ClientsEdit,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Clienti', route: 'clients.index' },
            { name: 'Modificare' }
          ]
        }
      },
      {
        path: '/clients/:client/licenses/create',
        name: 'clients.licenses.create',
        component: ClientLicensesCreate,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Clienti', route: 'clients.index' },
            { name: 'Nuovo Licenza' }
          ]
        }
      },
      {
        path: '/printers',
        name: 'printers.index',
        component: PrintersIndex,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Macchine' }
          ]
        }
      },
      {
        path: '/printers/:printer',
        name: 'printers.view',
        component: PrintersView,
        meta: {
          breadcrumbs: [
            { name: 'Dashboard', route: 'dashboard' },
            { name: 'Macchine', route: 'printers.index' },
            { name: 'Dettaglio' }
          ]
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
